<template>
  <div id="app">
    <svgList />
    <router-view />
    <loginDIalog />
  </div>
</template>
<script>
import loginDIalog from "@/components/loginDIalog";
import svgList from "@/components/svgList";
export default {
  components: {
    loginDIalog,
    svgList,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style>
</style>
