<template>
  <footer class="footer">
    <div class="section">
      <img class="logo-footer" src="@/assets/images/logo.png" alt="" />
      <p class="copyright">
        北京元空智能科技有限公司 |
        <a target="_blank" class="beian-link" href="https://beian.miit.gov.cn/">
          京ICP备2024050141号-3
        </a>
        |<a target="_blank"  @click="clickImg" class="a_me"> 联系我们 </a>
        <!-- <a
          target="_blank"
          class="beian-link"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010402351499"
          >本网站支持 IPv6 上海尔骋科技有限公司</a
        > -->
      </p>
    </div>
      <el-dialog title="" :visible.sync="showDialog" width="400px" >
       <img class="callme_img" src="@/assets/images/callme.png" alt="" />
      </el-dialog>
  </footer>
</template>

<script>
export default {
  name: "myFooter",
  data(){
    return{
      showDialog:false
    }
  },
  methods:{
    clickImg(){
      this.showDialog=true
      console.log(543)
    },
  }
};
</script>

<style>
.callme_img{
  width: 100%
}
.a_me{
  cursor: pointer;
}
</style>