import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue')
      },
      {
        path: '/category',
        name: 'category',
        component: () => import('../views/category/index.vue')
      },
      {
        path: '/customerInsight',
        name: 'customerInsight',
        component: () => import('../views/customerInsight/index.vue')
      },
      {
        path: '/marketingStrategy',
        name: 'marketingStrategy',
        component: () => import('../views/marketingStrategy/index.vue')
      },
      {
        path: '/popularFeelings',
        name: 'popularFeelings',
        component: () => import('../views/popularFeelings/index.vue')
      }
    ]
  },
  {
    path: '/flowPath',
    name: 'flowPath',
    component: () => import('../views/flowPath/index.vue')
  },
  {
    path: '/UserAgreement',
    component: () => import('../views/h5/UserAgreement.vue')
  },
  {
    path: '/PrivacyAgreement',
    component: () => import('../views/h5/PrivacyAgreement.vue')
  },
  {
    path: '/assembly',
    name: 'assembly',
    component: () => import('../views/assembly/index.vue')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
