import request from '@/utils/axios'
// import requestFlow from '@/utils/axios-flow'
export function getAccessToken() {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/auth/get-access-token' },
        headers: {
            isToken: false,
        },
        data: {
            appid: '5NnDQmEv94ZLNVA',
            appsecret: "1nrhJKzmK0tsq1SkPBqVqJ8tA4bhVTnQ"
        }
    })
}
export function userSendMsg(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/send-msg' },
        data: data
    })
}
export function userLogin(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/login' },
        data: data
    })
}
export function resetPassWord(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/reset-pass-word' },
        data: data
    })
}
export function applyForProbation(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/apply-for-probation' },
        data: data
    })
}

// export function getAgent(data) {
//     return requestFlow({
//         url: '/GetAgent',
//         method: 'post',
//         headers: {
//             "Content-Type": "application/json" // 如果写成contentType会报错
//         },
//         data: data
//     })
// }
// export function createAgent(data) {
//     return requestFlow({
//         url: '/CreateAgent',
//         method: 'post',
//         headers: {
//             "Content-Type": "application/json" // 如果写成contentType会报错
//         },
//         data: data
//     })
// }
// export function updateAgent(data) {
//     return requestFlow({
//         url: '/UpdateAgent',
//         method: 'post',
//         headers: {
//             "Content-Type": "application/json" // 如果写成contentType会报错
//         },
//         data: data
//     })
// }
