import { getCookie, setCookie } from '@/utils/cookie'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: getCookie('access_token') || '',
    login_token: getCookie('login_token') || '',
    mobile: getCookie('mobile') || '',
    pwd: getCookie('pwd') || '',
    loginDisplay: false
  },
  getters: {
  },
  mutations: {
    setToken(state, active) {
      state.access_token = active
      setCookie('access_token', active)
    },
    setLoginDisplay(state, active) {
      state.loginDisplay = active
    },
    setloginToken(state, active) {
      state.login_token = active
      setCookie('login_token', active)
    },
    setMobile(state, active) {
      state.mobile = active
      setCookie('mobile', active)
    }
  },
  actions: {

  },
  modules: {

  }
})
